import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "./contact.css";
import yourImage from "./website slide.png";
import facebookIcon from "./facebook-icon.png";
import instagramIcon from "./instagram.png";
import mailIcon from "./mainIcon.png";
const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    FullName: "",
    FatherName: "",
    Email: "",
    MobileNumber: "",
    EducationQualification: "",
    Gender: "",
    Profession: "",
    Location: "",
    BloodGroup: "",
    Message: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(value, name);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData, isLoading);
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://test1-hslr.onrender.com/users/hello",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Error occurred while sending data");
      }

      const data = await response.json();
      console.log("Data sent successfully:", data);
      setIsLoading(false);
      alert("Data submitted successfully!");
      setFormData({
        FullName: "",
        FatherName: "",
        Email: "",
        MobileNumber: "",
        EducationQualification: "",
        Gender: "",
        Profession: "",
        Location: "",
        BloodGroup: "",
        Message: "",
      });
    } catch (error) {
      console.error("Error:", error);
      // Handle error display or logging
    }
  };
  return (
    <div className="contactForm">
      <div>
        <img className="image" src={yourImage} alt="" />
      </div>
      <div className="contactForm1">
        {isLoading ? (
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        ) : (
          <form className="contactForm2" onSubmit={handleSubmit}>
            <span className="contactDesc">
              Please fill the below form to discuss about ideas,work &
              opportunities
            </span>
            <h4>
              Full Name <span className="required">*</span>
            </h4>
            <input
              type="text"
              className="w3-container w3-card-4 w3-light-grey"
              onChange={handleInputChange}
              name="FullName"
              placeholder="Full name"
              value={formData.FullName}
              pattern="^[a-zA-Z]+(\s[a-zA-Z]+)?$"
              required
            />
            <h4>
              Father Name <span className="required">*</span>
            </h4>
            <input
              type="text"
              className="w3-container w3-card-4 w3-light-grey"
              onChange={handleInputChange}
              name="FatherName"
              placeholder="Father name"
              pattern="^[a-zA-Z]+(\s[a-zA-Z]+)?$"
              title="Only alphabets and spaces are allowed"
              value={formData.FatherName}
              required
            />

            <h4>
              Email<span className="required">*</span>
            </h4>
            <input
              type="email"
              className="w3-container w3-card-4 w3-light-grey"
              onChange={handleInputChange}
              name="Email"
              placeholder="Email"
              value={formData.Email}
              required
            />
            <h4>
              Mobile Number/WhatsApp<span className="required">*</span>
            </h4>
            <input
              type="text"
              className="w3-container w3-card-4 w3-light-grey"
              placeholder="Mobile Number"
              onChange={handleInputChange}
              value={formData.MobileNumber}
              name="MobileNumber"
              minLength="10"
              maxLength="15"
              pattern="^\+\d{1,4}\d{6,14}$"
              title="please Include the country code (example: +91999,+1,+61)"
              required
            />

            <div className="edu">
              <h4>
                Education Qualification<span className="required">*</span>
              </h4>
              <select
                className="w3-container w3-card-4 w3-light-grey"
                name="EducationQualification"
                value={formData.EducationQualification}
                onChange={handleInputChange}
                required
              >
                <option value="Choose an option">Choose an option</option>
                <option value="Below 10th Class">Below 10th Class</option>
                <option value="10th Class">10th Class</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Graduation (B.Sc/B.Tech/B.com/MBBS)">
                  Graduation (B.Sc/B.Tech/B.com/MBBS)
                </option>
                <option value="Post Graduation(MCA/MBA/M.Tech etc)">
                  Post Graduation(MCA/MBA/M.Tech etc)
                </option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="row3">
              <div>
                <h4>
                  Gender<span className="required">*</span>
                </h4>
                <select
                  className="w3-container w3-card-4 w3-light-grey"
                  name="Gender"
                  value={formData.Gender}
                  onChange={handleInputChange}
                  required
                >
                  <option value="Choose an option">Choose an option</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div>
                <h4>
                  Profession<span className="required">*</span>
                </h4>
                <select
                  className="w3-container w3-card-4 w3-light-grey"
                  name="Profession"
                  onChange={handleInputChange}
                  value={formData.Profession}
                  required
                >
                  <option value="Choose an option">Choose an option</option>
                  <option value="Working proffesional">
                    Working professional
                  </option>
                  <option value="self employed/bussiness">
                    Self employed/Business
                  </option>
                  <option value="student">Student</option>
                  <option value="job search">Job search</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div>
                <h4>
                  Location<span className="required">*</span>
                </h4>
                <input
                  type="text"
                  className="w3-container w3-card-4 w3-light-grey"
                  placeholder="Location"
                  onChange={handleInputChange}
                  value={formData.Location}
                  name="Location"
                  pattern="^[a-zA-Z]+(\s[a-zA-Z]+)?$"
                  title="Only alphabets and spaces are allowed"
                  required
                />
              </div>
            </div>

            <h4>
              Blood Group<span className="required">*</span>
            </h4>
            <input
              type="text"
              className="w3-container w3-card-4 w3-light-grey"
              placeholder="BloodGroup"
              onChange={handleInputChange}
              value={formData.BloodGroup}
              name="BloodGroup"
              pattern="^(A|B|AB|O)[\+\-]$"
              title="Enter a valid blood group (A, B, AB, O) with Rh factor (+ or -)"
              required
            />
            <h4>
              Please leave your feedback or suggestions
              <span className="required">*</span>
            </h4>
            <textarea
              className="w3-container w3-card-4 w3-light-grey"
              rows="5"
              placeholder="Your Message"
              name="Message"
              onChange={handleInputChange}
              value={formData.Message}
              required
            ></textarea>
            <div className="ack">
              <span>
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  required
                />
              </span>
              <span>
                <label for="vehicle1">
                  I hereby declare and providing my due consent to use my
                  personal details provided herein to the Siripuram Seva
                  Samithi. I have no objection to use the details provided by me
                  in any respect except misuse of the same for commercial and
                  illegal activities by the recipient of this information. For
                  all other purposes Siripuram Seva Samithi can use these
                  details to inform, update and join me in the suitable groups
                  wherever and whenever felt my presence and role is important
                  and imminent.
                </label>
              </span>
            </div>
            <div className="btnCenter">
              <button type="submit" value="Send" className="submitBtn">
                Submit
              </button>
            </div>
          </form>
        )}
        <p className="us">
          <b>Connect with us on: </b>
        </p>
        <br></br>
        <div className="links">
          <a href="https://www.facebook.com/oursiripuram">
            <img src={facebookIcon} alt="FacebookIcon" className="link" />
          </a>

          <a href="https://instagram.com/manasiripuram">
            <img src={instagramIcon} alt="InstagramIcon" className="link" />
          </a>
          <a href="mailto:manasiripuram@gmail.com">
            <img src={mailIcon} alt="YoutubeIcon" className="link" />
          </a>
        </div>
        <br></br>
        <div>
          <p>
            <b className="us">CONTACT US:</b>
          </p>
          <p>Siripuram Seva Samithi,</p>
          <p>
            1St floor, Shri Kankanala Siva Rama krishnaiah bhavan,<br></br>{" "}
            Pedda Palakendram, Siripuram Post,<br></br> Medikondur Mandal,
            <br></br> Guntur District<br></br> Andhra Pradesh-522401<br></br>
            <b>Mobile No:</b>
            +91 9912634854
          </p>
          <br></br>
        </div>
        <p>
          Design and Devoloped by :-{" "}
          <b>మణికంఠ కుమార్ చేగు & వెలగా కళ్యాణ్ సాయి</b>
          <br></br>
          <b>(Irthe Global Services pvt.Ltd)</b>
        </p>

        <br></br>
        <p>
          <span>Copyright © 2023-2026</span>
          <b> Siripuram Seva Samithi</b>. All rights reserved.
        </p>
      </div>
    </div>
  );
};
export default Contact;
