import React, { useState, useEffect } from "react";
import "./contact.css";

const UserTable = () => {
  const [userData, setUserData] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submittedData, setSubmittedData] = useState(false);
  // console.log(userData);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Storing the entered data when the form is submitted
    if (username === "Sss@2022" && password === "sss@2022") {
      setSubmittedData(true);
    }
  };

  useEffect(() => {
    fetch("https://test1-hslr.onrender.com/users")
      .then((response) => response.json())
      .then((data) => {
        const uniqueData = data.users.filter(
          (obj, index, self) =>
            index === self.findIndex((o) => o.MobileNumber === obj.MobileNumber)
        );
        setUserData(uniqueData);
        console.log(uniqueData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      <div className="container">
        {!submittedData ? (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Username"
              className="inputField"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <br></br>
            <input
              type="password"
              placeholder="Password"
              className="inputField"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br></br>
            <button type="submit">Submit</button>
          </form>
        ) : (
          <table>
            <thead>
              <tr>
                <th>FullName</th>
                <th>Fathername</th>
                <th>Email</th>
                <th>MobileNumber</th>
                <th>EducationQualification</th>
                <th>Gender</th>
                <th>Profession</th>
                <th>Location</th>
                <th>BloodGroup</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {userData?.length > 0 ? (
                userData?.map((user, index) => (
                  <tr key={index}>
                    <td>{user.FullName}</td>
                    <td>{user.FatherName}</td>
                    <td>{user.Email}</td>
                    <td>{user.MobileNumber}</td>
                    <td>{user.EducationQualification}</td>
                    <td>{user.Gender}</td>
                    <td>{user.Profession}</td>
                    <td>{user.Location}</td>
                    <td>{user.BloodGroup}</td>
                    <td>{user.Message}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UserTable;
