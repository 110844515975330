import Contact from "./components/Contact/contact.js";
// import UserTable from "./components/Contact/table.js";
import { Routes, Route, HashRouter } from "react-router-dom";
import Information from "./pages/information.js";
function App() {
  return (
    <div className="App">
      {/* <Contact></Contact> */}
      {/* <UserTable></UserTable> */}
      <HashRouter>
        <Routes>
          <Route path="/" element={<Contact />} />

          <Route path="information" element={<Information />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
